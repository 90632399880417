import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './assets/base.scss';
import {getLanguage} from './Auth';
import Main from './AppPages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import {
    setTranslations,
    setDefaultLanguage,
} from 'react-switch-lang';
import en from './Lang/en.json';
import pt from './Lang/pt.json';
import es from './Lang/es.json';

setTranslations({ en, pt, es });

var userLang = navigator.language || navigator.userLanguage; 

if (userLang) {
    userLang = userLang.substring(0,2);
}

if (getLanguage()) {
    userLang = getLanguage();
}

switch (userLang) {
    case "pt":
        setDefaultLanguage('pt');
        break;
    case "es":
        setDefaultLanguage('es');
        break;
    case "en":
        setDefaultLanguage('en');
        break;
    default:
        setDefaultLanguage('en');
        break;
}

const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <Component />
            </BrowserRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./AppPages/Main', () => {
        const NextApp = require('./AppPages/Main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();

