import { Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'
import {PrivateRoute} from '../../PrivateRoute.js';

import {
    ToastContainer,
} from 'react-toastify';

const AccessPages = lazy(() => import('../../AppPages/AccessPages'));
const Platform = lazy(() => import('../../AppPages/Platform'));

const AppMain = () => {
    return (
        <Fragment>
        <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                        Por favor, aguarde!
                            <small>Estamos carregando as informações para você :)</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/platform" component={Platform}/>
            </Suspense>

            {/* AccessPages */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale-party"/>
                        </div>
                        <h6 className="mt-3">
                            Por favor, aguarde!
                            <small>Estamos carregando as informações para você :)</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/auth" component={AccessPages}/>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/auth/login"/>
            )}/>

            <ToastContainer autoClose={6000}
                            draggable={false}
                            position="top-right"
                            closeButton={false}
                            pauseOnHover={true}
                            hideProgressBar={true}/>
        </Fragment>
    )
};

export default AppMain;