import React from "react";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticated } from "./Auth.js";

export const PrivateRoute = ({ component: Component, refreshHeader, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} refreshHeader={refreshHeader}/>
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );