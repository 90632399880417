const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

export const server_host = process.env.REACT_APP_SERVER_HOST;
export const server_host_api = process.env.REACT_APP_SERVER_HOST_API;

export const finder_params_search = [
  { value: 1, label: "Atividades", finder: "activity", marketAnalysis: true },
  { value: 2, label: "Segmento", finder: "segment", marketAnalysis: true },
  { value: 3, label: "Range de funcionários", finder: "employee", marketAnalysis: true },
  { value: 4, label: "Estado", finder: "state", marketAnalysis: true },
  { value: 5, label: "Cidade", finder: "city", marketAnalysis: true },
  { value: 6, label: "Bairro", finder: "neighborhood", marketAnalysis: true },
  { value: 7, label: "Filial", finder: "branches", marketAnalysis: true },
  { value: 8, label: "Capital social", finder: "capital", marketAnalysis: true },
  { value: 9, label: "Natureza jurídica (Categoria)", finder: "legal_nature", marketAnalysis: true },
  { value: 10, label: "Tecnologia", finder: "tecnologia", marketAnalysis: true },
  { value: 11, label: "Anos de atividade", finder: "yearsActive", marketAnalysis: true },
  { value: 12, label: "Porte da empresa", finder: "size", marketAnalysis: true },
  { value: 13, label: "Simples", finder: "simple", marketAnalysis: false }, // Sim ou Não
  { value: 14, label: "Setor", finder: "sector", marketAnalysis: false },
  { value: 15, label: "Faturamento", finder: "billing", marketAnalysis: false },
  { value: 16, label: "Atividade econômica principal", finder: "activity_primary", marketAnalysis: false },
  { value: 17, label: "Atividade econômica secundária", finder: "activity_secondary", marketAnalysis: false },
  { value: 18, label: "Razão social", finder: "company_name", marketAnalysis: false },
  { value: 19, label: "Nome Fantasia", finder: "company_fantasy", marketAnalysis: false },
  //{ value: 20, label: "Inicia com na razão social", finder: "start_with_contain_in_company_name", marketAnalysis: false }, // true or false
  { value: 21, label: "Região", finder: "region", marketAnalysis: false },
  { value: 22, label: "Sócios", finder: "partner", marketAnalysis: false },
  { value: 23, label: "Redes sociais", finder: "social_media", marketAnalysis: false },
  { value: 24, label: "Ocultar contas salvas", finder: "hide_saved_accounts", marketAnalysis: false }, // true or false
  { value: 25, label: "Ocultar meus clientes", finder: "hide_my_customers", marketAnalysis: false }, // true or false
  { value: 26, label: "Importadora", finder: "importer", marketAnalysis: false }, // true or false
  { value: 27, label: "Exportadora", finder: "exporter", marketAnalysis: false }, // true or false
  //{ value: 28, label: "Ocultar com o mesmo nome", finder: "hide_same_name", marketAnalysis: false }, // true or false
  { value: 29, label: "Pesquisar apenas MEI", finder: "search_only_mei", marketAnalysis: false }, // true or false
  { value: 30, label: "Pesquisar apenas matriz", finder: "search_only_headquarters", marketAnalysis: false }, // true or false
  { value: 31, label: "Pesquisar apenas com site", finder: "search_only_with_site", marketAnalysis: false }, // true or false
  { value: 32, label: "Range de funcionários", finder: "employeersRange", marketAnalysis: false },
  { value: 33, label: "MEI", finder: "is_mei", marketAnalysis: false },
  { value: 34, label: "Importadora", finder: "importadora", marketAnalysis: false },
  { value: 35, label: "Exportadora", finder: "exportadora", marketAnalysis: false },
  { value: 36, label: "Faturamento", finder: "faturamento", marketAnalysis: false },
  { value: 37, label: "Atividade Divisão", finder: "Atividade Divisão", marketAnalysis: false },
  { value: 38, label: "Atividade Seção", finder: "Atividade Seção", marketAnalysis: false },
  { value: 39, label: "Matriz", finder: "Matriz", marketAnalysis: false },
  { value: 40, label: "Multinacional", finder: "Multinacional", marketAnalysis: false },
  { value: 41, label: "Grupo de Natureza Jurídica", finder: "legalNatureGroup", marketAnalysis: false },
  { value: 42, label: "Atividade/CNAE Primário", finder: "activityPrimary", marketAnalysis: false },
  { value: 43, label: "Atividade/CNAE Secudário", finder: "activitySecondary", marketAnalysis: false},
  { value: 44, label: "company_name_operator", finder: "company_name_operator", marketAnalysis: false},
  { value: 45, label: "cod_cnae_subclasse_secondary", finder: "cod_cnae_subclasse_secondary", marketAnalysis: false},
  { value: 46, label: "cod_cnae_subclasse", finder: "cod_cnae_subclasse", marketAnalysis: false},
  { value: 47, label: "cod_technologies", finder: "cod_technologies", marketAnalysis: false},
  { value: 48, label: "cod_socialNetwork", finder: "cod_socialNetwork", marketAnalysis: false},
  { value: 49, label: "Natureza jurídica (Subcategoria)", finder: "legal_nature_sub", marketAnalysis: false },
  { value: 50, label: "Optante Simples", finder: "search_only_simples", marketAnalysis: false },
  { value: 51, label: "Cargo", finder: "jobTitle", marketAnalysis: false },
  { value: 52, label: "cod_job_title", finder: "cod_job_title", marketAnalysis: false },
  { value: 53, label: "Regime Tributário", finder: "tax_regime", marketAnalysis: false },
  { value: 54, label: "Natureza da Dívida", finder: "nature_debt", marketAnalysis: false },
  { value: 55, label: "Valor Dívida - De", finder: "valueDebtDe", marketAnalysis: false },
  { value: 56, label: "Valor Dívida - Até", finder: "valueDebtAte", marketAnalysis: false },
  { value: 57, label: "Empresa(s) sem Dívida(s)", finder: "companyofDebt", marketAnalysis: false },
  { value: 58, label: "cod_infoNatureDebt", finder: "cod_infoNatureDebt", marketAnalysis: false },
  { value: 59, label: "Pat", finder: "search_only_pat", marketAnalysis: false },
  { value: 60, label: "Suframa", finder: "suframa", marketAnalysis: false },
  { value: 61, label: "Histórico Tecnologia", finder: "technology_historic", marketAnalysis: false },
  { value: 62, label: "cod_company_search", finder: "cod_company_search", marketAnalysis: false },
  { value: 63, label: "cod_partners", finder: "cod_partners", marketAnalysis: false },
  { value: 64, label: "Capital Social", finder: "shareCapital", marketAnalysis: false },
];

export const calendar_locale = {
  localize: {
    month: (n) => months[n],
    day: (n) => days[n],
  },
  formatLong: {},
};

export const select_style = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    fontSize: 12,
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? "0 0 8px #00e4a1" : 0,
    borderColor: state.isFocused ? "rgba(0, 228, 161, .6)" : base.borderColor,
    "&:hover": {
      borderColor: state.isFocused ? "rgba(0, 228, 161, .6)" : base.borderColor,
    },
  }),
  option: (
    styles,
    { backgroundColor, data, isDisabled, isFocused, isSelected }
  ) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "rgba(0, 228, 161, .8)"
        : isFocused
        ? "rgba(0, 228, 161, .2)"
        : backgroundColor,
      color: data.backgroundColor != null ? data.backgroundColor : "#495057",
      ":hover": {
        backgroundColor: isFocused ? "rgba(0, 228, 161, .2)" : backgroundColor,
        color: data.backgroundColor != null ? data.backgroundColor : "#495057",
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor:
        data.backgroundColor != null ? data.backgroundColor : "#E6E6E6",
      color: data.color != null ? data.color : "#495057",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color != null ? data.color : "#495057",
  }),
};

export const IsEmail = (email) => {
  //eslint-disable-next-line
  var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  if (!regex.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const getLanguageApi = () => {
  var userLang = navigator.language || navigator.userLanguage;

  if (userLang) {
    userLang = userLang.substring(0, 2);
  }

  if (userLang === "pt") {
    return "pt-br";
  } else {
    return userLang;
  }
};

export const ValidarCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  var tamanho = cnpj.length - 2;
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(1)) return false;

  return true;
};

export const ConstructFullAddress = (address) => {
  var strPart1 = "";
  var strPart2 = "";
  var strPart3 = "";
  var strPart4 = "";
  var strPart5 = "";

  if (address.street != null) {
    strPart1 += address.street;

    if (address.number != null) {
      strPart1 += ", " + address.number;
    }
  }

  if (address.complement !== null && address.complement !== "") {
    if (strPart1 !== "") {
      strPart2 = ", " + address.complement;
    } else {
      strPart2 = address.complement;
    }
  }

  if (address.neighborhood !== null) {
    if (strPart1 !== "" || strPart2 !== "") {
      strPart3 = " - " + address.neighborhood + ". ";
    } else {
      strPart3 = address.neighborhood + ". ";
    }
  }

  if (address.city != null) {
    if (address.state != null) {
      strPart4 = address.city + " - " + address.state + ".";
    } else {
      strPart4 = address.city + ".";
    }
  } else {
    if (address.state != null) {
      strPart4 = address.state + ".";
    }
  }

  if (address.zipcode != null) {
    strPart5 = " " + address.zipcode.replace(/^(\d{5})(\d{3})/, "$1-$2") + ".";
  }

  return strPart1 + strPart2 + strPart3 + strPart4 + strPart5;
};

export const FormatPhone = (phone) => {
  /*
  Formatos tratados

  3707865			    7   Fixo sem DDD, antigo
  33707865			  8   Fixo sem DDD
  91962409			  8   Celular sem DDD, antigo
  448233965			  9   Fixo com DDD, antigo sem o 4 dígito no início do fone 
  991962409			  9   Celular sem DDD
  2732279840			10  Fixo com DDD, sem o zero no DDD
  47991962409			11  Celular com DDD, sem o zero no DDD
  
  */

  if (phone[0] === "0") {
    if (phone[1] === "0") {
      phone = phone.substring(2, phone.length);
    } else {
      phone = phone.substring(1, phone.length);
    }
  }

  switch (phone.length) {
    case 7:
      return phone.replace(/^(\d{3})(\d{4})/, "$1-$2");
    case 8:
      return phone.replace(/^(\d{4})(\d{4})/, "$1-$2");
    case 9:
      if (phone.charAt(0) === "9") {
        return phone.replace(/^(\d{5})(\d{4})/, "$1-$2");
      } else {
        return phone.replace(/^(\d{2})(\d{3})(\d{4})/, "($1) $2-$3");
      }
    case 10:
      return phone.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    case 11:
        return phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    case 12: 
      return phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
    case 13:
      if(phone[0] === "5") {
        return phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4");
      } else {
        return phone.replace(/^(\d{4})(\d{5})(\d{4})/, "($1) $2-$3");
      };  
    default:
        return null    
  }
};
